:root{
  --primary-color: 	#1e499b;
  --secondary-color: 	#ff9920;
  --text-color:	#000000;
  --body-color: #f0dbec;
  --hero-color: #dbdded;
}

h1, h2, h3, h4, h5, h6{
  color: var(--secondary-color)!important;
}

a{
  color: var(--text-color)!important;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/**------------------------------------------------------------------------
 **                            FOOTER
 *------------------------------------------------------------------------**/

footer{
  padding: 2rem!important;
  background-color: var(--text-color);
  color: #FFF!important;
}

footer p, a{
  font-size: 0.9rem;
}

footer h3{
  margin-bottom: 1rem;
  color: var(--secondary-color);
  font-size: 1.5rem;
  text-transform: uppercase;
}

footer ul li{
  list-style-type: none;
}

footer ul li:hover a{
  letter-spacing: 2px;
  color: var(--primary-color)!important;
}

footer ul a, footer ul svg{
  line-height: 2.5rem;
  text-decoration: none;
  color: #FFF!important;
  transition: 0.4s;
}

/**------------------------------------------------------------------------
 **                            COPYRIGTH
 *------------------------------------------------------------------------**/
#copyrigth {

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);

}
#copyrigth .container{
  padding: 1rem!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

#copyrigth .container .row p{
  font-size: 0.8rem!important;
  color: #FFF;
}

#copyrigth .container .row p a{
  font-size: 1rem!important;
  color: var(--secondary-color)!important;
}
.navbar{
  background-color: var(--primary-color);
  color: #FFF;
}

.navbar .container .navbar-brand {
  text-decoration: none!important;
  font-size: 1.8rem;
  font-weight: 700;
  color: #FFF!important;
}

.navbar .container .collapse .navbar-nav .nav-item .nav-link{
  color: #FFF!important;
  transition: all 0.3s;
}

.navbar .container .collapse .navbar-nav .nav-item .nav-link:hover{
  color: var(--secondary-color)!important;
}

.active{
  border-bottom: 1px solid var(--secondary-color)!important;
}
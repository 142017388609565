

.label{
    position: relative;
    padding: 1rem 1.5rem;
    min-height: 1.5rem!important;
    width: 100%;
    border: 1px solid grey;
    border-radius: 12px;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.8rem;
    transition: 0.4s;
}

input[type='radio']{
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}


input[type='radio']:checked{
    width: 20px;
    height: 20px;
}

.label:hover{
    box-shadow: 6px 6px #989898;
}

.form-label{
    font-size: 1.1rem;    
}

input[type='text'], input[type='email'], input[type='password']{
    box-shadow: none!important;
    min-height: 45px;
}


form button{
    background: var(--primary-color)!important;
    color: var(--body-color)!important;
}